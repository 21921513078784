import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGeoLocation, loginAPI,logoutAPI } from "./reduxAPI";

const initialState = {
  loading: false,
  signup: false,
  rememberMe: false,
  fullName: "",
  location: "",
  ipAddress: "",
  isAuthenticated: false,
};

export const loginAsync = createAsyncThunk(
  "auth/loginAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { fullName, location, ipAddress } = getState().auth;
      const response = await loginAPI(fullName, location, ipAddress);
      navigate("/");
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const logoutAsync = createAsyncThunk(
  "auth/logoutAPI",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { fullName, location, ipAddress } = getState().auth;
      const response = await logoutAPI(fullName, location, ipAddress);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getGeoLocationAsync = createAsyncThunk(
  "auth/getGeoLocation",
  async ({ latitude, longitude }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getGeoLocation(latitude, longitude);
      const address = response.results[0].formatted_address;
      return address;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSignUp: (state, action) => {
      state.signup = action.payload.value;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload.value;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload.value;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setSytemIp: (state, action) => {
      state.ipAddress = action.payload.ipAddress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        window.sessionStorage.setItem("isAuthenticated", true);
        window.localStorage.setItem("fullName", state.fullName);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getGeoLocationAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGeoLocationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.location = action.payload;
      })
      .addCase(getGeoLocationAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setSignUp,
  setRememberMe,
  setFullName,
  setLocation,
  setSytemIp,
} = authSlice.actions;
export const authState = (state) => state.auth;
export default authSlice.reducer;
