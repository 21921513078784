import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

export default function CustomCountdown() {
  const [targetTime, setTargetTime] = useState(null);

  useEffect(() => {
    const today = new Date(); // Get the current date
    today.setHours(23); // Set the hours to 4 PM
    today.setMinutes(45); // Set the minutes to 45

    setTargetTime(today.getTime());
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Typography variant="h6" component="h4" className="text-center">
          Event has started, join now !!
        </Typography>
      );
    } else {
      const formattedDays = String(days).padStart(2, "0");
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      return (
        <>
          <Typography
            variant="h6"
            component="h4"
            className="text-center"
            sx={{ fontWeight: 200 }}
          >
            Event starts in
          </Typography>
          <Box className="d-flex gap-2">
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedDays}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Days
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedHours}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Hours
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedMinutes}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Minutes
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedSeconds}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Seconds
              </Typography>
            </Box>
          </Box>
        </>
      );
    }
  };

  return targetTime ? (
    <Countdown date={targetTime} renderer={renderer} />
  ) : null;
}
