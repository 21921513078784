import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./component/component/Auth/App";
import Video from "./component/component/Video/App";
import { useSelector } from "react-redux";
import { authState } from "./component/component/Auth/reduxSlice";
import * as React from "react";
import LandscapeMessage from "./component/component/Video/LandscapeMessage"
const PrivateRouteWrapper = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useSelector(authState);

  return isAuthenticated ? (
    <Element {...rest} />
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  return (
    <>
      <LandscapeMessage />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Login />} />
        <Route path="/" element={<PrivateRouteWrapper element={Video} />} />
      </Routes>
    </>
  );
}

export default App;
