import React, { useEffect, useState } from 'react';
import gif from "../../../assets/rotate-screen-icon.gif";

const LandscapeMessage = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia('(orientation: landscape)').matches
  );

  const handleOrientationChange = (event) => {
    setIsLandscape(event.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: landscape)');
    mediaQuery.addListener(handleOrientationChange);
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);

  if (!isLandscape) {
    return (
      <div className="position-absolute d-flex flex-column justify-content-center align-items-center" style={{backgroundColor:'rgba(0,0,0,.75)',zIndex:1000, height:'100vh', width:'100vw'}}>
        <img src={gif} alt="..." height={60} width={60} className="mb-2"/>
        <p className='text-light'>Please rotate your device to landscape mode.</p>
      </div>
    );
  }

  return null;
};

export default LandscapeMessage;
