import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Modal, IconButton, Divider } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import logoGif from "../../../assets/vid-1920.gif";
import axios from "axios";
import {
  authState,
  setRememberMe,
  setFullName,
  loginAsync,
  setSytemIp,
  getGeoLocationAsync,
} from "./reduxSlice";
import { useSelector, useDispatch } from "react-redux";
import CustomCountdown from "../Count/Count";

const style = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: 0,
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Typography
        component="a"
        variant="body2"
        color="inherit"
        href="https://bizconnect.events/"
        target="_blank"
      >
        BizConnect Event
      </Typography>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSide() {
  const {
    signup,
    rememberMe,
    isAuthenticated,
    ipAddress,
    fullName,
    location,
    loading,
  } = useSelector(authState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    getUserIP();
    // eslint-disable-next-line
  }, [dispatch]);

  const getUserIP = async () => {
    return await axios
      .get("https://api.ipify.org/?format=json")
      .then((response) => dispatch(setSytemIp({ ipAddress: response.data.ip })))
      .catch((error) => {
        console.log("Error retrieving IP address:", error);

        return null;
      });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    // if (rememberMe) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      window.localStorage.setItem("latitude", latitude);
      window.localStorage.setItem("longitude", longitude);
      await dispatch(getGeoLocationAsync({ latitude, longitude }));
      if(fullName){
        dispatch(loginAsync({ navigate }));
       }
    } catch (error) {
      if (error.code === error.PERMISSION_DENIED) {
        console.log("Geolocation permission denied");
      } else {
        console.log("Error getting location:", error.message);
      }
      if(fullName){
        dispatch(loginAsync({ navigate }));
       }
    }
    // }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // adding 1 day
  tomorrow.setHours(16); // hours to 4 pm
  tomorrow.setMinutes(30); // minutes to 30 min

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          background: `url(${logoGif})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100% !important",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: 250, md: 392 },
            left: { xs: 25, md: 146 },
          }}
        >
          <Typography variant="h5" component="h4" color="#eee">
            <CustomCountdown />
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: 20, md: 138 },
            borderRadius: 2,
            display: "flex",
            p: 5,
            left: { xs: 25, md: 118 },
            bgcolor: "rgba(255,255,255,0.6)",
            backdropFilter: "blur(8px)",
            WebkitBackdropFilter: "blur(8px)", // For Safari support
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleLogin}
            className="d-flex flex-column m-auto"
          >
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="fullName"
              label="Full Name"
              type="text"
              id="fullName"
              helperText="We'll never share your name with anyone else."
              onChange={(e) => {
                const fullName = e.target.value;
                dispatch(setFullName({ value: fullName }));
              }}
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if (
                  (charCode >= 65 && charCode <= 90) || // Uppercase letters
                  (charCode >= 97 && charCode <= 122) || // Lowercase letters
                  charCode === 32 // Space
                ) {
                  return; // Allow input
                }
                e.preventDefault(); // Prevent the input of other characters
              }}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value="remember"
                  color="primary"
                  checked={rememberMe ? true : false}
                  onClick={() => {
                    handleOpen();
                    dispatch(setRememberMe({ value: !rememberMe }));
                  }}
                />
              }
              label="Check me in"
            /> */}
            {loading ? (
              <LoadingButton loading variant="outlined">
                Submit
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                size="small"
                fullWidth
                variant="contained"
                className="mt-3"
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {/* <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12}>
              <Divider className="my-1" />
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  overflowY: "scroll",
                }}
              >
                <Typography variant="caption" component="p">
                  See the documentation below for a complete reference to all of
                  the props and classes available to the components mentioned
                  here. See the documentation below for a complete reference to
                  all of the props and classes available to the components
                  mentioned here. See the documentation below for a complete
                  reference to all of the props and classes available to the
                  components mentioned here. See the documentation below for a
                  complete reference to all of the props and classes available
                  to the components mentioned here. See the documentation below
                  for a complete reference to all of the props and classes
                  available to the components mentioned here.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-end mt-2 ">
              <Button
                size="small"
                variant="contained"
                startIcon={<CheckIcon />}
                onClick={() => handleClose()}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
    </>
  );
}
