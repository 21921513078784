import { Container } from "react-bootstrap";
import * as React from "react";
import {
  Grid,
  Box,
  Popper,
  Typography,
  Button,
  Fade,
  IconButton,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import DownloadIcon from "@mui/icons-material/Download";

export default function App() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClick = (newPlacement) => (event) => {
    setOpen(true);
    setOpen2(false);
  };
  const handleClick2 = (newPlacement) => (event) => {
    setOpen2(true);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <Container
      fluid
      className="m-0 p-0 vh-100"
      style={{
        overflow: "hidden",
        background: "#0c0c0c",
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        className="p-0 m-0 flex-nowrap"
        sx={{ height: "100%" }}
      >
        <Grid
          item
          xs={9}
          className="p-0 m-0 d-flex align-items-center"
          style={{ height: "100%" }}
        >
          <div className="ratio ratio-16x9 ">
            <iframe
              title="BizConnect"
              src="https://vimeo.com/event/3500857/embed"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </Grid>
        {/* <Grid
          item
          xs={3}
          className="p-3 m-0 d-flex flex-column align-items-end justify-content-between gap-1"
          sx={{ height: "100%" }}
        >
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: open ? "block" : "none",
              bgcolor: "rgba(255,255,255,0.6)",
              backdropFilter: "blur(8px)",
              WebkitBackdropFilter: "blur(8px)", // For Safari support
              zIndex: 5,
            }}
          >
            <Grid container spacing={0} sx={{ mx: 0 }}>
              <Grid
                item
                xs={12}
                className="d-flex justify-content-between align-items-center p-4"
                sx={{ zIndex: 5 }}
              >
                <Typography variant="subtitle2" component="p">
                  Chat Now
                </Typography>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} className="d-flex flex-column gap-2 px-4">
                <Divider />
                <div
                  style={{
                    height: "70vh",
                    width: "100%",
                  }}
                >
                  <iframe
                    title="BizConnect"
                    src=" https://bizchat.bizconnect.space/generalChat/odAq3zUe7NYXIPm8hTHPghICqTt2/NurtureSubharambh"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  ></iframe>
                </div>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            className=" p-4"
            sx={{
              width: "100%",
              height: "100%",
              display: open2 ? "block" : "none",

              bgcolor: "rgba(255,255,255,0.6)",
              backdropFilter: "blur(8px)",
              WebkitBackdropFilter: "blur(8px)", // For Safari support
              zIndex: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                className="d-flex justify-content-between align-items-center"
                sx={{ zIndex: 5 }}
              >
                <Typography variant="subtitle2" component="p">
                  Download Documents
                </Typography>
                <IconButton size="small" onClick={handleClose2}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} className="d-flex flex-column gap-2">
                <Divider />
                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText primary="Sample" />
                      <ListItemIcon>
                        <DownloadIcon sx={{ ml: "auto", mr: "0" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>

          <div className="mt-auto">
            <IconButton
              size="large"
              sx={{ bgcolor: "#1976d2 !important", color: "#fff" }}
              onClick={handleClick("top-end")}
            >
              <ChatIcon />
            </IconButton>
            <IconButton
              size="large"
              sx={{ bgcolor: "#1976d2 !important", color: "#fff", ml: 2 }}
              onClick={handleClick2("top-start")}
            >
              <FolderIcon />
            </IconButton>
          </div>
        </Grid> */}
      </Grid>
    </Container>
  );
}