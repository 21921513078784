import { httpsCallable } from "firebase/functions";
import {functions } from "../../../firebase/config";

import Geocode from "react-geocode";

// login
export function loginAPI(fullName,location,ipAddress) {
  const login = httpsCallable(functions, "login");
  return login({ fullName,location,ipAddress });
}
export function logoutAPI(fullName,location,ipAddress) {
  const login = httpsCallable(functions, "logOut");
  return login({ fullName,location,ipAddress });
}

export function getGeoLocation(latitude, longitude) {
  Geocode.setApiKey("AIzaSyB9oNVBHPHcz60Tf6b2hPwT-gaVFWtODlE");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  return Geocode.fromLatLng(latitude, longitude);
}
